import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputSelect = _resolveComponent("InputSelect")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.isAvailableShow)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(_ctx.ufClass)
        }, [
          _createVNode(_component_InputSelect, {
            label: "UF:",
            name: "uf",
            modelValue: _ctx.uf,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.uf) = $event)),
            onChange: _ctx.carregarMunicipios,
            placeholder: "Selecione uma das opções",
            required: _ctx.required,
            options: _ctx.stateList,
            disabled: _ctx.disabled
          }, null, 8 /* PROPS */, ["modelValue", "onChange", "required", "options", "disabled"])
        ], 2 /* CLASS */))
      : _createCommentVNode("v-if", true),
    (_ctx.isAvailableShow)
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: _normalizeClass(_ctx.municipioClass)
        }, [
          _createVNode(_component_InputSelect, {
            label: "Município:",
            name: "municipio_id",
            modelValue: _ctx.municipio,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.municipio) = $event)),
            onChange: _ctx.onChange,
            placeholder: "Selecione uma das opções",
            required: _ctx.required,
            options: _ctx.municipios,
            disabled: _ctx.disabled
          }, null, 8 /* PROPS */, ["modelValue", "onChange", "required", "options", "disabled"])
        ], 2 /* CLASS */))
      : _createCommentVNode("v-if", true)
  ], 64 /* STABLE_FRAGMENT */))
}