import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, vModelText as _vModelText, withModifiers as _withModifiers, withKeys as _withKeys, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-67ce7287"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "input-select" }
const _hoisted_2 = {
  class: "dropdown",
  ref: "dropdownRef"
}
const _hoisted_3 = { key: 0 }
const _hoisted_4 = {
  key: 1,
  class: "placeholder",
  style: {"background-color":"rgba(0, 0, 0, 0)","cursor":"pointer !important"}
}
const _hoisted_5 = {
  key: 0,
  class: "dropdown-menu show"
}
const _hoisted_6 = ["onClick"]
const _hoisted_7 = {
  key: 0,
  class: "dropdown-item disabled"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.label)
      ? (_openBlock(), _createElementBlock("label", {
          key: 0,
          class: _normalizeClass({ required: _ctx.required })
        }, _toDisplayString(_ctx.label), 3 /* TEXT, CLASS */))
      : _createCommentVNode("v-if", true),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: _normalizeClass(["dropdown-toggle form-control", { disabled: _ctx.disabled }]),
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleDropdown && _ctx.toggleDropdown(...args))),
        style: _normalizeStyle({ border: _ctx.borderStyle })
      }, [
        (_ctx.selectedLabel)
          ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.selectedLabel), 1 /* TEXT */))
          : (_openBlock(), _createElementBlock("span", _hoisted_4, " Selecione uma das opções "))
      ], 6 /* CLASS, STYLE */),
      (_ctx.isOpen)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _withDirectives(_createElementVNode("input", {
              ref: "searchInput",
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.searchQuery) = $event)),
              type: "text",
              class: "form-control",
              placeholder: "Pesquisar...",
              onKeydown: _cache[2] || (_cache[2] = _withKeys(_withModifiers(
//@ts-ignore
(...args) => (_ctx.selectFirstOption && _ctx.selectFirstOption(...args)), ["prevent"]), ["enter"]))
            }, null, 544 /* NEED_HYDRATION, NEED_PATCH */), [
              [_vModelText, _ctx.searchQuery]
            ]),
            _createElementVNode("div", {
              class: "dropdown-options",
              ref: "optionsContainer",
              onScroll: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.onScroll && _ctx.onScroll(...args)))
            }, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.displayedOptions, ({ value, label }, index) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: `${_ctx.name}-${value}-${index}`,
                  class: "dropdown-item",
                  onClick: ($event: any) => (_ctx.selectOption(value, label)),
                  tabindex: "0"
                }, _toDisplayString(label), 9 /* TEXT, PROPS */, _hoisted_6))
              }), 128 /* KEYED_FRAGMENT */)),
              (_ctx.displayedOptions.length === 0)
                ? (_openBlock(), _createElementBlock("div", _hoisted_7, " Nenhum resultado encontrado. "))
                : _createCommentVNode("v-if", true)
            ], 544 /* NEED_HYDRATION, NEED_PATCH */)
          ]))
        : _createCommentVNode("v-if", true)
    ], 512 /* NEED_PATCH */)
  ]))
}