<template>
  <div class="input-select">
    <label v-if="label" :class="{ required: required }">{{ label }}</label>
    <div class="dropdown" ref="dropdownRef">
      <div
        class="dropdown-toggle form-control"
        :class="{ disabled: disabled }"
        @click="toggleDropdown"
        :style="{ border: borderStyle }"
      >
        <span v-if="selectedLabel">{{ selectedLabel }}</span>
        <span
          v-else
          class="placeholder"
          style="background-color: rgba(0, 0, 0, 0); cursor: pointer !important"
        >
          Selecione uma das opções
        </span>
      </div>

      <div v-if="isOpen" class="dropdown-menu show">
        <input
          ref="searchInput"
          v-model="searchQuery"
          type="text"
          class="form-control"
          placeholder="Pesquisar..."
          @keydown.enter.prevent="selectFirstOption"
        />

        <div class="dropdown-options" ref="optionsContainer" @scroll="onScroll">
          <div
            v-for="({ value, label }, index) in displayedOptions"
            :key="`${name}-${value}-${index}`"
            class="dropdown-item"
            @click="selectOption(value, label)"
            tabindex="0"
          >
            {{ label }}
          </div>
          <div
            v-if="displayedOptions.length === 0"
            class="dropdown-item disabled"
          >
            Nenhum resultado encontrado.
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  ref,
  computed,
  onBeforeUnmount,
  nextTick,
  watch,
} from "vue";
import SelectData from "@/components/Inputs/interface/IInputSelect";

export default defineComponent({
  name: "InputSelect",
  props: {
    options: {
      type: Array as () => SelectData[],
      default: () => [],
    },
    modelValue: {
      type: [String, Number],
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: "",
    },
    required: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: "select",
    },
  },
  emits: ["update:modelValue", "change"],
  setup(props, { emit }) {
    const isOpen = ref(false);
    const searchQuery = ref("");
    const dropdownRef = ref<HTMLElement | null>(null);
    const searchInput = ref<HTMLInputElement | null>(null);
    const optionsContainer = ref<HTMLElement | null>(null);
    const isTouched = ref(false);

    const visibleCount = ref(20);
    const increment = 20;

    watch(searchQuery, () => {
      visibleCount.value = 20;
    });

    const selectedValue = computed({
      get: () => props.modelValue,
      set: (newValue: string | number) => {
        emit("update:modelValue", newValue);
      },
    });

    const selectedLabel = computed(() => {
      if (!Array.isArray(props.options)) return "";
      if (selectedValue.value == undefined) return "";
      const selectedOption = props.options.find(
        (option) => option.value === props.modelValue
      );
      return selectedOption ? selectedOption.label : "";
    });

    const filteredOptions = computed(() => {
      return props.options.filter(({ label }) =>
        label?.toLowerCase().includes(searchQuery.value.toLowerCase())
      );
    });

    const displayedOptions = computed(() => {
      let optionsList = props.required
        ? [...filteredOptions.value]
        : [{ value: "", label: " " }, ...filteredOptions.value];

      return optionsList.slice(0, visibleCount.value);
    });

    const borderStyle = computed(() => {
      if (!isTouched.value) return "";
      if (props.required && !selectedValue.value) {
        return "1px solid red";
      } else if (!props.required) {
        return "1px solid green";
      }
      return "";
    });

    const toggleDropdown = () => {
      isOpen.value = !isOpen.value;
      if (isOpen.value) {
        searchQuery.value = "";
        visibleCount.value = 20;
        nextTick(() => {
          searchInput.value?.focus();
        });
      }
    };

    const selectOption = (value: any, label: any) => {
      selectedValue.value = value;
      emit("change", { target: { value } });
      isOpen.value = false;
    };

    const handleClickOutside = (event: Event) => {
      if (
        dropdownRef.value &&
        !dropdownRef.value.contains(event.target as Node)
      ) {
        isOpen.value = false;
      }
    };

    const selectFirstOption = () => {
      if (filteredOptions.value.length > 0) {
        const { value, label } = filteredOptions.value[0];
        selectOption(value, label);
      }
    };

    const onScroll = () => {
      const container = optionsContainer.value;
      if (container) {
        const { scrollTop, clientHeight, scrollHeight } = container;
        if (scrollTop + clientHeight >= scrollHeight - 20) {
          if (visibleCount.value < filteredOptions.value.length) {
            visibleCount.value = Math.min(
              visibleCount.value + increment,
              filteredOptions.value.length
            );
          }
        }
      }
    };

    document.addEventListener("click", handleClickOutside);
    onBeforeUnmount(() => {
      document.removeEventListener("click", handleClickOutside);
    });

    return {
      isOpen,
      searchQuery,
      filteredOptions,
      displayedOptions,
      toggleDropdown,
      selectOption,
      selectedLabel,
      dropdownRef,
      searchInput,
      optionsContainer,
      selectFirstOption,
      borderStyle,
      onScroll,
    };
  },
});
</script>

<style scoped>
.input-select {
  position: relative;
  width: 100%;
}

.dropdown {
  position: relative;
}

.placeholder {
  display: block;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.dropdown-toggle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 0.25rem;
  cursor: pointer !important;
  background-color: #fff;
  padding: 0.75rem;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.dropdown-toggle span {
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: calc(100% - 1.5rem);
}

.dropdown-toggle.disabled {
  cursor: not-allowed;
  opacity: 0.65;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  width: 100%;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
  margin-top: 0.125rem;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
}

.dropdown-options {
  max-height: 200px;
  overflow-y: auto;
  margin-top: 0.5rem;
}

.dropdown-item {
  cursor: pointer;
  min-height: 20px;
}

.dropdown-item:hover {
  background-color: #f8f9fa;
}

.dropdown-item.disabled {
  cursor: not-allowed;
  color: #6c757d;
}

.dropdown-toggle.disabled {
  background-color: #eff2f5 !important;
  pointer-events: none;
  cursor: not-allowed;
  color: #64626a !important;
}
</style>
