const breadCrumb = "Certificado de Conclusão - Emissão de Certificado";

const routes = [
  {
    path: "/manutencao-de-remessa-de-formulario",
    name: "manutencao-de-remessa-de-formulario",
    meta: {
      allowedPermissions: ["parametro_certificado_list"],
      breadCrumb: breadCrumb,
    },
    component: () =>
      import(
        "@/views/ManutencaoRemessaFormulario/ManutencaoRemessaFormularioHome.vue"
      ),
  },
  {
    path: "/manutencao-de-remessa-de-formulario/novo/:escola_id/:coordenadoria_id?/:rede?/:municipio?",
    name: "manutencao-de-remessa-de-formulario-novo",
    meta: {
      allowedPermissions: ["parametro_certificado_list"],
      breadCrumb: breadCrumb,
    },
    component: () =>
      import(
        "@/views/ManutencaoRemessaFormulario/ManutencaoRemessaFormularioCad.vue"
      ),
  },
  {
    path: "/manutencao-de-remessa-de-formulario/editar/:id",
    name: "manutencao-de-remessa-de-formulario-editar",
    meta: {
      allowedPermissions: ["parametro_certificado_list"],
      breadCrumb: breadCrumb,
    },
    component: () =>
      import(
        "@/views/ManutencaoRemessaFormulario/ManutencaoRemessaFormularioEdit.vue"
      ),
  },
  {
    path: "/manutencao-de-remessa-de-formulario/visualizar/:id",
    name: "manutencao-de-remessa-de-formulario-visualizar",
    meta: {
      allowedPermissions: ["parametro_certificado_list"],
      breadCrumb: breadCrumb,
    },
    component: () =>
      import(
        "@/views/ManutencaoRemessaFormulario/ManutencaoRemessaFormularioVisualizar.vue"
      ),
  },
  {
    path: "/manutencao-de-remessa-de-formulario/cancelar/:id",
    name: "manutencao-de-remessa-de-formulario-cancelar",
    meta: {
      allowedPermissions: ["parametro_certificado_list"],
      breadCrumb: breadCrumb,
    },
    component: () =>
      import(
        "@/views/ManutencaoRemessaFormulario/ManutencaoRemessaFormularioCancelar.vue"
      ),
  },
  {
    path: "/manutencao-de-remessa-de-formulario/excluir/:id",
    name: "manutencao-de-remessa-de-formulario-excluir",
    meta: {
      allowedPermissions: ["parametro_certificado_list"],
      breadCrumb: breadCrumb,
    },
    component: () =>
      import(
        "@/views/ManutencaoRemessaFormulario/ManutencaoRemessaFormularioExcluir.vue"
      ),
  },
];

export default routes;
