const breadCrumb = "Certificado de Conclusão - Emissão de Certificado";

const routes = [
  {
    path: "/atualizacao-conclusao-ensino",
    name: "atualizacao-conclusao-ensino",
    meta: {
      allowedPermissions: ["parametro_certificado_list"],
      breadCrumb: breadCrumb,
    },
    component: () =>
      import(
        "@/views/AtualizacaoConclusaoEnsino/AtualizacaoConclusaoEnsino.vue"
      ),
  },
];

export default routes;
