<template>
  <div :class="ufClass" v-if="isAvailableShow">
    <InputSelect
      label="UF:"
      name="uf"
      v-model="uf"
      @change="carregarMunicipios"
      placeholder="Selecione uma das opções"
      :required="required"
      :options="stateList"
      :disabled="disabled"
    />
  </div>
  <div :class="municipioClass" v-if="isAvailableShow">
    <InputSelect
      label="Município:"
      name="municipio_id"
      v-model="municipio"
      @change="onChange"
      placeholder="Selecione uma das opções"
      :required="required"
      :options="municipios"
      :disabled="disabled"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import axios from "axios";
import { stateList } from "@/core/listas/stateList";
import InputSelect from "@/components/Inputs/InputSelect/InputSelect.vue";

export default defineComponent({
  name: "MunicipiosUf",
  components: { InputSelect },
  props: {
    modelValue: {
      type: [String, Number],
      required: false,
      default: "",
    },
    isAvailableShow: {
      type: Boolean,
      default: true,
    },
    municipioClass: {
      type: String,
      default: "col-sm-4",
    },
    ufClass: {
      type: String,
      default: "col-sm-2",
    },
    currentUf: {
      type: String,
      default: "",
    },
    currentMunicipio: {
      type: [String, Number],
      default: "",
    },
    required: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update:modelValue"],
  data() {
    return {
      municipios: [],
      uf: this.currentUf || "",
      municipio: this.currentMunicipio || "",
    };
  },
  computed: {
    stateList() {
      return stateList;
    },
  },
  methods: {
    async carregarMunicipios(event) {
      const uf = event?.target?.value || event;
      if (uf) {
        axios
          .get(
            process.env.VUE_APP_ORGANIZACAO_GEOGRAFICA_API +
              "/municipio/listagem/" +
              uf
          )
          .then((response) => {
            this.municipios = response.data.dados.map((dado) => {
              return {
                value: dado.id,
                label: dado.nome,
              };
            });
          })
          .catch(() => (this.municipios = []));
      }
    },
    onChange() {
      return this.$emit("update:modelValue", this.municipio);
    },
  },
  watch: {
    currentUf(value) {
      if (value) {
        this.uf = value;
        this.carregarMunicipios(value);
      }
    },
    modelValue(modelValue) {
      this.municipio = modelValue;
    },
    isAvailableShow(value) {
      if (!value) {
        this.municipio = "";
        this.uf = "";
      }
    },
  },
  mounted() {
    if (this.currentUf) {
      this.carregarMunicipios(this.currentUf);
    }
  },
});
</script>
